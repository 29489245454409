@tailwind components;

@layer components{

.container-fluid {
    @apply mx-auto max-w-screen-4xl;
} 

.row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }  

.breadcrumb{
    @apply container-fluid bg-[#F5F5F5] py-[16px] my-[60px] hidden md:block lg:block
}  

.videos_wrap{
    @apply container-fluid mt-[100px] my-[60px];
}

.videos_heads{
    @apply uppercase text-[30px] md:text-[50px] lg:text-[50px] font-suzukiBold text-[#001151] leading-[62px] mb-[35px]
}

.videos_icons{
    @apply absolute z-[1] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]
}

/* .video_grid{
    @apply cursor-pointer w-[100%] md:w-[24%] lg:w-[24%] mb-[35px] md:mb-[95px] lg:mb-[95px] md:px-[16px] lg:px-[16px]
} */

.video_grid{
  @apply cursor-pointer w-[100%] 
}

.video_grid_head{
    @apply text-left mb-[5px] mt-[20px] text-[14px] max-h-[40px] overflow-hidden overflow-ellipsis ;
}

.video_grid_paragraph {
  @apply text-left max-h-[60px] overflow-hidden;
}

}